import {UploadsMediaType} from '@Panels/user-media-panel/user-media-panel.types';
import {hideLoading, showLoading} from '@Libraries/loading-toast-library';
import type {ImageData, VideoData} from '@Libraries/add-media-library';
import {openUserMediaModal} from '@Modals/user-media-modal';
import {isUserLoggedIn} from '@Libraries/user.library';
import {openErrorModal} from '@Modals/error-modal';
import type {CreateDesignFromUploadOpts} from '@Components/design-selector/design-selector.types';
import {openAuthenticateModal} from '@Modals/authenticate-modal';

window.PMW.mergePMW(PMW, {
  triggerCreateDesignFromUserUpload(opts: CreateDesignFromUploadOpts) {
    const createPosters = async (items: Array<ImageData | VideoData>, currentIndex?: number, totalPosterCreationsCount?: number): Promise<void> => {
      const posterCreationPromises: Array<Promise<any>> = [];

      showLoading(`createDesignFromUpload`, {text: window.i18next.t('pmwjs_creating')});
      for (let index = 0; index < items.length; index++) {
        posterCreationPromises.push(createPoster(items[index], currentIndex ?? index + 1, totalPosterCreationsCount ?? items.length));
      }

      await Promise.all(posterCreationPromises);

      hideLoading(`createDesignFromUpload`);

      if (currentIndex === totalPosterCreationsCount) {
        if (opts.onAllPostersCreated) {
          opts.onAllPostersCreated(allCreatedPosterIds, failedUploads);
        }
      }
    };

    const createPoster = async (item: ImageData | VideoData, currentIndex?: number, totalPosterCreationsCount?: number): Promise<void> => {
      try {
        const newPosterHashedId = (await window.PMW.writeLocal('poster/createPosterForMediaItem', {
          type: item.type,
          hashedFilename: item.hashedFilename,
          fid: opts.idFolder,
        })) as string;

        opts.onPosterCreated({
          newlyCreatedPosterHashedId: newPosterHashedId,
          posterCreatedFromMediaType: item.type,
          currentIndex,
          totalPosterCreationsCount,
        });
        allCreatedPosterIds.push(newPosterHashedId);
      } catch (error) {
        console.error(error);
        openErrorModal();
      }
    };

    const onAddSelected = async (items: Array<ImageData | VideoData>, currentUploadIndex?: number, totalUploadsCount?: number): Promise<void> => {
      if (!isUserLoggedIn()) {
        openAuthenticateModal({
          fbPermissions: '',
          signupSource: 'default',
          onLoginSuccess: async () => {
            await createPosters(items, currentUploadIndex, totalUploadsCount);
          },
        });
        return;
      }
      await createPosters(items, currentUploadIndex, totalUploadsCount);
    };

    const onErrorDuringUpload = (file: File, currentUploadIndex?: number, totalItemsToUpload?: number): void => {
      failedUploads.push(file);

      if (opts.onErrorDuringUpload) {
        opts.onErrorDuringUpload(file, currentUploadIndex, totalItemsToUpload);
      }

      if (currentUploadIndex === totalItemsToUpload) {
        if (opts.onAllPostersCreated) {
          opts.onAllPostersCreated(allCreatedPosterIds, failedUploads);
        }
      }
    };

    const allCreatedPosterIds: Array<string> = [];
    const failedUploads: Array<File> = [];

    if (opts.onTriggered) {
      opts.onTriggered();
    }

    openUserMediaModal({
      onAddSelectedCTAText: opts.footerPrimaryCtaText ?? window.i18next.t('pmwjs_create_design'),
      maxSelectionNumber: opts.maxSelectionNumber,
      onAddSelected,
      defaultTab: UploadsMediaType.IMAGE,
      mediaTypesToShow: [UploadsMediaType.IMAGE, UploadsMediaType.VIDEO],
      uploadAcceptedMimetype: 'image/*,video/*',
      onStartAddSelected: opts.onPosterCreationsStarted,
      onUploadError: onErrorDuringUpload,
      isForDesignUpload: true,
      hideFooterSecondaryActions: opts.hideFooterSecondaryActions,
    });
  },
});
