import type {ReactElement} from 'react';
import React from 'react';
import {Panel, PanelContent, PanelHeader} from '@Components/panel';
import {getAssetUrl} from '@Utils/s3.util';
import {ControlledList} from '@Components/controlled-list';
import type {ControlledListItem2} from '@Components/controlled-list/controlled-list.types';
import {CONTROLLED_LIST_ITEM_TYPE} from '@Components/controlled-list/controlled-list.types';
import {ThumbnailType} from '@Components/thumbnail/thumbnail.types';
import {Pill, PILL_BACKGROUND_COLOR, PILL_CONTENT_TYPE, PILL_SIZE} from '@Components/pill';
import {TextSize} from '@Components/text';
import {useIsSmallerThanBreakpointWidth} from '@Hooks/useIsSmallerThanBreakpoint';
import {SCREEN_BREAKPOINTS} from '@Libraries/responsiveness-library';
import useDefaultPanelClose from '@Panels/hooks/useDefaultPanelClose';
import type {HomepageCreatePopupPanelProps} from './homepage-create-popup-panel.types';
import {PANEL_ID} from './homepage-create-popup-panel.types';
import styles from './homepage-create-popup-panel.module.scss';

/**
 * @author Moeed Ahmad <moeed@250mils.com>
 *
 */
export function HomepageCreatePopupPanel({includeHeader = false, ...props}: HomepageCreatePopupPanelProps): ReactElement {
  const isMobile = useIsSmallerThanBreakpointWidth(SCREEN_BREAKPOINTS.HOMEPAGE_MOBILE_BREAKPOINT);
  const parentPaddingBuffer = 16;
  const widthToSet = 360;
  const width = isMobile ? '100%' : `${widthToSet - parentPaddingBuffer}px`;
  const onModalClose = useDefaultPanelClose(PANEL_ID);
  const getComingSoonPill = (): ReactElement => {
    return (
      <Pill
        content={{
          text: window.i18next.t('pmwjs_coming_soon'),
          type: PILL_CONTENT_TYPE.TEXT,
          textSize: TextSize.XXSMALL,
          textColor: 'content-secondary',
        }}
        className="spacing-p-t-0 spacing-p-b-0 spacing-p-l-3 spacing-p-r-3 spacing-m-l-2"
        size={PILL_SIZE.XSMALL}
        background={PILL_BACKGROUND_COLOR.SECONDARY_10}
      />
    );
  };

  const getDropdownOptions = (): ControlledListItem2[] => {
    const thumbnailContainerClasses = `flexbox ${styles.thumbWithImageLoader}`;
    const dropdownOptions: ControlledListItem2[] = [];

    if (props.onClickCreateDesign) {
      dropdownOptions.push({
        id: 'create_design',
        onClick: props.onClickCreateDesign,
        subText: window.i18next.t('pmwjs_homepage_popup_design_desc'),
        text: window.i18next.t('pmwjs_design'),
        thumbnail: {
          type: ThumbnailType.IMAGE,
          imageSrc: getAssetUrl('homepage-create-design-popup-option-graphic.webp'),
        },
        type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_2,
        className: 'flex-align-items-center',
        thumbnailContainerClasses,
      });
    }

    if (props.onClickCreateSocialMediaPost) {
      dropdownOptions.push({
        id: 'create_social_media_post',
        onClick: props.onClickCreateSocialMediaPost,
        subText: window.i18next.t('pmwjs_homepage_popup_smp_desc'),
        text: window.i18next.t('pmwjs_social_media_post'),
        thumbnail: {
          type: ThumbnailType.IMAGE,
          imageSrc: getAssetUrl('homepage-create-smp-popup-option-graphic.webp'),
        },
        type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_2,
        className: 'flex-align-items-center',
        thumbnailContainerClasses,
      });
    }

    if (props.onClickCreateEmail) {
      dropdownOptions.push({
        id: 'create_email',
        onClick: props.onClickCreateEmail,
        subText: window.i18next.t('pmwjs_homepage_popup_email_campaigns_desc'),
        text: window.i18next.t('pmwjs_email'),
        thumbnail: {
          type: ThumbnailType.IMAGE,
          imageSrc: getAssetUrl('homepage-create-email-popup-option-graphic.webp'),
        },
        type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_2,
        className: 'flex-align-items-center',
        thumbnailContainerClasses,
      });
    }

    if (props.onClickCreateEvent) {
      dropdownOptions.push({
        id: 'create_event',
        onClick: props.onClickCreateEvent,
        subText: window.i18next.t('pmwjs_homepage_popup_events_desc'),
        text: window.i18next.t('pmwjs_event'),
        thumbnail: {
          type: ThumbnailType.IMAGE,
          imageSrc: getAssetUrl('homepage-create-event-popup-option-graphic.webp'),
        },
        type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_2,
        className: 'flex-align-items-center',
        thumbnailContainerClasses,
        pill: getComingSoonPill(),
      });
    }

    return dropdownOptions;
  };

  const getHeader = (): ReactElement | null => {
    if (!includeHeader) {
      return null;
    }

    return <PanelHeader className="spacing-m-b-4" onClose={onModalClose} centerAlignTitle title={window.i18next.t('pmwjs_start_creating')} />;
  };

  return (
    <Panel
      panelId={PANEL_ID}
      panelWidth={width}
      panelHeader={getHeader()}
      panelContent={
        <PanelContent>
          <ControlledList items={getDropdownOptions()} />
        </PanelContent>
      }
    />
  );
}
